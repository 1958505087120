

.sidebarStyleMin {
    position: fixed;
    height: 100vh;
    right:  0;
    top: 0;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;


}


.listItemMin {
    font-size: 14px;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
    border-bottom: 1px solid lightgray;

    &:hover {
        background-color: white;
        cursor: pointer;
    }


}

.activeMin {
    background-color: #198754;
    color: white;
    font-weight: 600;

    font-size: 14px;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
}


@media screen and (min-width: 350px ) and (max-width: 600px) {
    // .sidebarStyle {
    //     width: 18.75rem !important;
    // }
}

.sidebarStyleMin::-webkit-scrollbar {
    display: none;
}