body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}




.mcBackground {
  background: url("./assets/Digitization\ landing\ page.jpg");
  background-size: cover;
  min-height: 100vh;
} 

.mcBackgroundLogo {
  background: url("./assets/Digital-Now-3.png");
  background-size: cover;
  min-height: 150px;
} 

.mc-mt-5 {
  margin-top: 3.8rem;
}


@media only screen and (max-width: 800px){
  .mcBackgroundLogo{
    min-height: 90px;
    background-color: aliceblue;
  }
}