.textarea-like {
    width: auto;
    height: auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.5;
    resize: vertical; 
    overflow: auto;
    white-space: pre-wrap; 
    box-sizing: border-box;
    background-color: #f7fafa;
    word-break: break-word;
  }

  .textarea-like-highlight {
    width: auto;
    height: auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.5;
    resize: vertical; 
    overflow: auto;
    white-space: pre-wrap; 
    box-sizing: border-box;
    background-color: #f0e54c;
    word-break: break-word;
  }

  .editIcon {
    color: blue;
    float: right;
    top :10px;
    right: 10px;
}