/* Styles for the main dropdown */
#dropdown-basic {
    border: none;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  #dropdown-basic:hover,
  #dropdown-basic:focus,
  #dropdown-basic:active {
    outline: none;
    box-shadow: none;
  }
  
  /* Styles for the nested dropdown */
  .nested-dropdown-container {
    position: relative;
  }
  
  .nested-dropdown {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .nested-dropdown:hover {
    background-color: #f8f9fa;
  }
  
  .nested-dropdown-menu {
    position: static; /* Ensure it stays within the main dropdown */
    margin-left: 1rem; /* Adjust the positioning as needed */
    padding-top: 0;
  }
  
  .nested-dropdown-menu .dropdown-item {
    white-space: nowrap;
  }
  
  /* nested inside*/
  .nested-dropdown-container1 {
    position: relative;
  }
  
  .nested-dropdown1 {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .nested-dropdown1:hover {
    background-color: #f8f9fa;
  }
  
  .nested-dropdown-menu1 {
    position: relative; /* Ensure it stays within the main dropdown */
    margin-left: 1rem; /* Adjust the positioning as needed */
    padding-top: 0;
  }
  
  .nested-dropdown-menu1 .dropdown-item {
    white-space: nowrap;
  }

  

  /* .download-button-container {
    position: absolute;
    float: right;
    top: 20px;
    right: 250px;
    margin-right: 3rem; 
}*/

.download-button {
    background-color:ghostwhite;
    color: black;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 12px;
    cursor: pointer;
    display: block;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.download-button:hover {
    background-color: lightgrey;
    transform: scale(1.1);
}
 
