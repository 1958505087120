.floating-save-button-container {
    position: fixed;
    bottom: 90px;
    right: 0px;
    margin-right: 3rem;
}

.floating-save-button {
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.floating-save-button:hover {
    background-color: #218838;
    transform: scale(1.1);
}

