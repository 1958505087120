@import "../../Utils/theme.scss";

.validators{
    display: block;
    background-color: #FFBABA;
    color: #D8000C;
    font-size: 12px;
    text-align: center;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    border: 1px solid;
}

.formErrors{
    font-size: 12px;
    color: red;
    height: 20px;
}

.formFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
}

.customFormField {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background: white;
}

.customButton{
    width: 100%;
    color: #fff;
    padding: 0.5rem 2rem;
    border: none;
    background-color: $primary-color;
}

.customButton:disabled{
    opacity: 0.6;
}

.customLink{
    cursor: pointer;
    color: $brand-color;
    padding: 0.25rem;
    background-color: #fff;
    border: none;
}

@media only screen and (min-width: 600px){
    .modalWidth{
        width: 420px !important;
    }
}

.logoLabel{
    color: #4a4a4a;
    font-weight: 400;
    text-align: center;
}

.customInput {
    input::-ms-reveal,
    input::-ms-clear {
      display: none;
    }
}

