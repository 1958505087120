.scrollTop{
    position: fixed;
    z-index: 1000;
    bottom: 40px;
    right: 0px;
    margin-right: 3rem;
    opacity: 0.5;
    border-radius: 50%;
    padding: 0px 10px;
    background-color: #478166;
    color: #fff;
    font-size: 1.5rem;
    border: none;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    float: right;
}