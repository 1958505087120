.floating-download-button-container {
    position: fixed;
    top: 20px;
    right: 250px;
    margin-right: 3rem;
}

.floating-download-button {
    background-color:darkgoldenrod;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.floating-download-button:hover {
    background-color: darkgoldenrod;
    transform: scale(1.1);
}

