@import "../../Utils/theme.scss";

//Layout CSS

.root {
    position: static;
    background-color:  black;
    bottom: 0;
}

//Header CSS
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.dropdownColor{
    color: white;
    background-color: #3D415A;
}

.dropdownColor:hover{
    color: white;
    background-color: #3D415A;
}

//Footer CSS

.footer1 {
    background-color:  #DD962A;
    color: #000000;
    min-height: 414px;
    align-content: flex-end;
    justify-content: space-around;
    padding: 2px 0px 15px 15px;
}

.footer2 {
    color: white;
    min-height: 57px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
}



.footer1 p {
    font-style: normal;
    margin-bottom: 5px;
    color: #000000;
    text-align: start;
}

.footer2 a{
    text-decoration: none;
}


@media only screen and (min-width: 600px){
    .modalWidth{
        width: 420px !important;
    }
}





