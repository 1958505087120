.table-responsive {
    overflow-x: auto;
  }
  
  #mainTable {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Ensure table respects fixed column widths */
  }
  
  #mainTable th, #mainTable td {
    /* border: 1px solid #ddd;
    padding: 8px; */
    word-wrap: break-word; /* Ensure word wrap */
    white-space: normal; /* Allow wrapping inside the cell */
    overflow: hidden; /* Prevent overflow */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
  }

  .button-container {
    display: flex;
    gap: 2px; /* Adjust the gap between buttons as needed */
  }
  
  .customBtn {
    padding: 2px 2px;
    font-size: 11px;
    cursor: pointer;
    /* Additional button styling */
  }

.content-div:focus {
  outline: none;
  /* You can also set a custom border style if needed */
  border: 1px solid transparent;  /* Example: you can remove border if needed */
}

.h5, h5 {
  font-size: 16px !important;
}

.css-13cymwt-control {
  font-size: 14px !important;
}
.card-body {
text-align: left !important;
padding: 10px 5px !important;
}


.custom-select-width {
  width: 12em; /* Adjust this width as needed */
  
}

.col-md-2-custom {
  width: 18.6667% !important;
  /* Optional: Ensure the column behaves correctly in the grid */
  float: left;
  box-sizing: border-box;
}

.col-md-4-custom {
  width: 30.3333% !important;
  /* Optional: Ensure the column behaves correctly in the grid */
  float: left;
  box-sizing: border-box;
}

